.component--contact {
    grid-row-gap: 2em;
    grid-auto-flow: dense;
    grid-template-rows: auto;

    h2 {
        grid-column: 1 / -1;
        font-family: $f-display;
        font-size: 2.25em;
        text-align: center;

        @include breakpoint($bp-s) {
            font-size: calc(2.25em + (3.75em - 2.25em) / 3);
        }

        @include breakpoint($bp-ml) {
            grid-column: 2 / span 4;
            text-align: right;
        }

        @include breakpoint($bp-l) {
            font-size: 3.75em;
        }
    }

    h3 {
        grid-column: 1 / -1;
        font-style: italic;
        font-size: 2.25em;
        font-weight: 300;
        text-align: center;

        @include breakpoint($bp-s) {
            font-size: calc(2.25em + (3.75em - 2.25em) / 3);
        }

        @include breakpoint($bp-ml) {
            grid-column: span 5 / -2;
            text-align: left;
        }

        @include breakpoint($bp-l) {
            font-size: 3.75em;
        }
    }

    p {
        grid-column: 1 / -1;
        font-size: 1.125em;
        line-height: 1.2em;
        text-align: center;

        @include breakpoint($bp-ml) {
            grid-column: span 5 / -2;
            text-align: left;
        }
    }

    &-map {
        grid-column: 1 / -1;
        width: 100%;

        @include breakpoint($bp-s) {
            height: 20em;
        }

        @include breakpoint($bp-sm) {
            height: 25em;
        }

        @include breakpoint($bp-m) {
            height: 30em;
        }

        @include breakpoint($bp-ml) {
            grid-column: span 5 / -2;
        }

        @include breakpoint($bp-l) {
            height: 40em;
        }
    }

    &-form {
        grid-column: 1 / -1;
        width: 100%;
        height: auto;

        @include breakpoint($bp-ml) {
            grid-column: span 5 / -2;
        }

        label {
            font-weight: 300;
            font-style: italic;
            font-size: 1.25em;
            text-align: center;
            display: block;

            @include breakpoint($bp-xs) {
                text-align: left;
            }
        }

        input, textarea {
            background-color: $c-zozan-beige;
            border: 1px solid $c-zozan-beige;
            box-shadow: none;
            display: block;
            width: 100%;
            padding: 0.5em;
            margin: 0.5em 0 1.5em;
            border: none;
            border-radius: 4px;
            font-family: $f-main;
            font-size: 1em;
        }

        button {
            display: block;
            width: 80%;
            max-width: 300px;
            background-color: $c-zozan-grey;
            border-color: $c-zozan-grey;
            color: $c-zozan-beige;
            text-transform: uppercase;
            font-weight: 900;
            font-family: $f-main;
            padding: 1em;
            display: block;
            text-align: center;
            font-size: 1.5em;
            margin: 1.5em auto 0;
            border-radius: 4px;

            &:hover {
                background-color: $c-black;
                border-color: $c-black;
            }
        }
    }

    &-info {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto calc(4px + 2em) auto auto auto minmax(0, 1fr);

        @include breakpoint($bp-ml) {
            grid-column: 2 / span 4;
        }

        &-line {
            grid-column: 1 / -1;
        }

        h2 {
            font-size: 1.75em;
            margin-bottom: 1em;

            @include breakpoint($bp-ml) {
                grid-column: 1 / -1;
                text-align: right;
            }
        }

        h3 {
            font-size: 1.75em;
            margin-bottom: 1em;

            @include breakpoint($bp-ml) {
                grid-column: 1 / -1;
                text-align: right;
            }
        }

        p {
            margin-bottom: 1em;

            @include breakpoint($bp-ml) {
                grid-column: 1 / -1;
                text-align: right;
            }
        }
    }
}
