header#header {
    justify-items: start;
    align-content: center;
    grid-auto-flow: dense;
    margin-top: 1.5em;

    div#header-logo {
        grid-column: 1 / -1;
        margin-top: 1em;
        margin-bottom: 2em;

        display: grid;
        justify-items: center;
        align-content: center;

        @include breakpoint($bp-s) {
            grid-column: span 4;
            margin-bottom: 1em;
        }
        @include breakpoint($bp-ml) {
            grid-column: span 2;
            margin-top: 0;
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }
    }

    nav#header-navigation {
        display: none;

        @include breakpoint($bp-s) {
            display: block;
            width: 100%;
            grid-column: 1 / -1;
        }

        @include breakpoint($bp-ml) {
            grid-column: span 6;
        }

        @include breakpoint($bp-xl) {
            grid-column: span 4;
        }

        ul {
            display: grid;
            margin: 1.5em 0 3em;
            width: 100%;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: 20px;
            grid-row-gap: 0.25em;
            grid-auto-flow: dense;
            align-content: center;

            @include breakpoint($bp-ml) {
                margin: 4.5em 0;
            }

            li {
                font-weight: 900;
                text-transform: uppercase;
                grid-column-start: 1;

                &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                    grid-column-start: 2;
                }

                a {
                    font-family: $f-main;
                    font-size: 2em;
                }
            }
        }
    }

    nav#header-languages {
        font-family: $f-main;
        font-weight: 300;
        width: 100%;
        justify-items: center;
        align-content: center;
        display: none;

        @include breakpoint($bp-s) {
            display: grid;
            width: 100%;
            grid-column: span 2 / -1;
        }

        @include breakpoint($bp-ml) {
            grid-column: span 1 / -1;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-column-gap: 0.5em;
            justify-content: center;
            align-content: center;

            li {
                display: inline-block;


            }
        }
    }

    nav#header-mobile-navigation {
        height: inherit;
        line-height: inherit;
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint($bp-s) {
            display: none;
        }

        > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            background-color: $c-zozan-grey;

            > li {
                display: inline-block;
                padding: 0;

                > a {
                    border: 0;
                    display: block;
                    font-size: 0.8em;
                    letter-spacing: initial;
                    padding: 0 1.5em 0 0.8em;
                    text-transform: uppercase;

                    &.menuToggle {
                        outline: 0;
                        position: relative;

                        &:after {
                            background-image: url('../../img/bars.svg');
                            background-position: right center;
                            background-repeat: no-repeat;
                            content: '';
                            display: inline-block;
                            height: 3.75em;
                            vertical-align: top;
                            width: 2em;
                        }

                        @include breakpoint($bp-s) {
                            padding: 0 1.5em;

                            span {
                                display: none;
                            }
                        }
                    }

                    @include breakpoint($bp-s) {
                        padding: 0 0 0 1.5em;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
