#menu {
    @include vendor('transform', 'translateX(24em)');
    @include vendor('transition', 'transform #{_duration(menu)} ease');
    -webkit-overflow-scrolling: touch;
    background: $c-zozan-beige;
    color: $c-zozan-grey;
    height: 100%;
    max-width: 80%;
    overflow-y: auto;
    padding: 3em 2em;
    position: fixed;
    right: 0;
    top: 0;
    width: 20em;
    z-index: _misc(z-index-base) + 2;

    ul {
        list-style: none;
        padding: 0;

        > li {
            margin: 0.5em 0 0 0;

            &:first-child {
                border-top: 0 !important;
                margin-top: 0 !important;
                padding-top: 0 !important;
            }

            > a {
                border: 0;
                color: $c-zozan-grey;
                display: block;
                font-size: 1em;
                letter-spacing: initial;
                outline: 0;
                text-decoration: none;
            }
        }
    }

    .close {
        border: 0;
        cursor: pointer;
        display: block;
        height: 1.4em;
        position: absolute;
        right: 0;
        top: 0;
        vertical-align: middle;
        width: 1.4em;
        text-align: center;
        color: $c-zozan-grey;
        font-size: 1.4em;
        margin: 0.75em 0.75em 0 0;
    }

    div.line {
        width: 100px;
        height: 10px;
        background-color: $c-zozan-grey;
        margin: 1em 0;
    }

    @include breakpoint($bp-xxs) {
        padding: 3em 1.5em;
    }

    @include breakpoint($bp-s) {
        display: none;
    }
}

body.is-menu-visible {
    #page-wrapper {
        opacity: 0.35;

        &:before {
            display: block;
        }
    }

    #menu {
        @include vendor('transform', 'translateX(0)');
    }
}
