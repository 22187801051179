// typefaces
$f-main: 'Lato', sans-serif;
$f-display: 'Playfair Display SC', serif;

// ---- breakpoints ----

$bp-xxs: 20em; /* 320px */
$bp-xs: 35.5em; /* 568px */
$bp-s: 37.5em; /* 600px */
$bp-sm: 48em; /* 768px */
$bp-m: 50em; /* 800px */
$bp-ml: 64em; /* 1024px */
$bp-l: 80em; /* 1280px */
$bp-xl: 115em; /* 1840px */
$bp-xxl: 160em; /* 2560px */

// ---- colors ----

$c-black: rgb(0, 0, 0);
$c-white: rgb(255, 255, 255);
$c-white-trans: rgba(255, 255, 255, .8);
$c-zozan-grey: #504b4c;
$c-zozan-kaki: #9ba587;
$c-zozan-beige: #f3f2f0;

$c-error: #f72f2f;
$c-error--light: lighten($c-error, 32);
$c-error--lightest: lighten($c-error, 36);

// ---- z-index ----

$z-overlay: 18;
$z-overlay-background: 15;
$z-nav-plus: 12;
$z-nav: 9;
$z-regular-plus: 6;
$z-regular: 3;
$z-neutral: 0;
$z-background: -1;

//---- misc ----

$misc: (
    max-spotlights:		10,
    max-features:		10,
    z-index-base:		900
);

$duration: (
    transitions: 0.2s,
    menu: 0.5s,
    fadein: 3s
);
