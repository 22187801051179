.component--banner-group {
    grid-row-gap: 20px;

    &-gallery {
        grid-column: 1 / -1;
        grid-row: span 1;
        position: relative;
        width: 100%;

        @include breakpoint($bp-m) {
            grid-column: 1 / span 8;
            grid-row: 1 / span 2;
        }

        &-gallery-image {
            width: 100%;
            padding-top: 67%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .slick-prev, .slick-next {
            position: absolute;
            top: 50%;
            left: 0;
            width: 20%;
            max-width: 60px;
            height: auto;
            padding: 0;
            z-index: 100;
            background: transparent;
            border: none;
            transform: translate(0, -50%);

            img {
                width: 20%;
                min-width: 60px;
                height: auto;
            }
        }

        .slick-next {
            left: 100%;
            transform: translate(-100%, -50%);
        }
    }

    &-image {
        grid-column: 1 / -1;
        width: 100%;
        padding-top: 65%;
        position: relative;

        @include breakpoint($bp-m) {
            grid-column: span 4 / -1;
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
