body {
    font-family: $f-main;
    color: $c-zozan-grey;

    a {
        color: $c-zozan-grey;
        text-decoration: none;

        &.active {
            font-style: italic;
            color: $c-black;
        }

        &:hover {
            color: $c-black;
        }
    }

    div#container {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto auto auto;

        .container-item {
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            grid-auto-flow: dense;
            grid-column-gap: 20px;
            max-width: 1840px;
            margin: 0 1.5em;
        }
    }
}
