/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* 320px */
/* 568px */
/* 600px */
/* 768px */
/* 800px */
/* 1024px */
/* 1280px */
/* 1840px */
/* 2560px */
body {
  font-family: "Lato", sans-serif;
  color: #504b4c;
}

body a {
  color: #504b4c;
  text-decoration: none;
}

body a.active {
  font-style: italic;
  color: black;
}

body a:hover {
  color: black;
}

body div#container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto auto;
}

body div#container .container-item {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 20px;
  max-width: 1840px;
  margin: 0 1.5em;
}

.line {
  background-color: #f3f2f0;
  height: 4px;
  width: 100%;
}

header#header {
  justify-items: start;
  align-content: center;
  grid-auto-flow: dense;
  margin-top: 1.5em;
}

header#header div#header-logo {
  grid-column: 1 / -1;
  margin-top: 1em;
  margin-bottom: 2em;
  display: grid;
  justify-items: center;
  align-content: center;
}

@media (min-width: 37.5em) {
  header#header div#header-logo {
    grid-column: span 4;
    margin-bottom: 1em;
  }
}

@media (min-width: 64em) {
  header#header div#header-logo {
    grid-column: span 2;
    margin-top: 0;
    margin-bottom: 0;
  }
}

header#header div#header-logo img {
  width: 100%;
}

header#header nav#header-navigation {
  display: none;
}

@media (min-width: 37.5em) {
  header#header nav#header-navigation {
    display: block;
    width: 100%;
    grid-column: 1 / -1;
  }
}

@media (min-width: 64em) {
  header#header nav#header-navigation {
    grid-column: span 6;
  }
}

@media (min-width: 115em) {
  header#header nav#header-navigation {
    grid-column: span 4;
  }
}

header#header nav#header-navigation ul {
  display: grid;
  margin: 1.5em 0 3em;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 0.25em;
  grid-auto-flow: dense;
  align-content: center;
}

@media (min-width: 64em) {
  header#header nav#header-navigation ul {
    margin: 4.5em 0;
  }
}

header#header nav#header-navigation ul li {
  font-weight: 900;
  text-transform: uppercase;
  grid-column-start: 1;
}

header#header nav#header-navigation ul li:nth-child(4), header#header nav#header-navigation ul li:nth-child(5), header#header nav#header-navigation ul li:nth-child(6) {
  grid-column-start: 2;
}

header#header nav#header-navigation ul li a {
  font-family: "Lato", sans-serif;
  font-size: 2em;
}

header#header nav#header-languages {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  width: 100%;
  justify-items: center;
  align-content: center;
  display: none;
}

@media (min-width: 37.5em) {
  header#header nav#header-languages {
    display: grid;
    width: 100%;
    grid-column: span 2 / -1;
  }
}

@media (min-width: 64em) {
  header#header nav#header-languages {
    grid-column: span 1 / -1;
  }
}

header#header nav#header-languages ul {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 0.5em;
  justify-content: center;
  align-content: center;
}

header#header nav#header-languages ul li {
  display: inline-block;
}

header#header nav#header-mobile-navigation {
  height: inherit;
  line-height: inherit;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 37.5em) {
  header#header nav#header-mobile-navigation {
    display: none;
  }
}

header#header nav#header-mobile-navigation > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  background-color: #504b4c;
}

header#header nav#header-mobile-navigation > ul > li {
  display: inline-block;
  padding: 0;
}

header#header nav#header-mobile-navigation > ul > li > a {
  border: 0;
  display: block;
  font-size: 0.8em;
  letter-spacing: initial;
  padding: 0 1.5em 0 0.8em;
  text-transform: uppercase;
}

header#header nav#header-mobile-navigation > ul > li > a.menuToggle {
  outline: 0;
  position: relative;
}

header#header nav#header-mobile-navigation > ul > li > a.menuToggle:after {
  background-image: url("../../img/bars.svg");
  background-position: right center;
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  height: 3.75em;
  vertical-align: top;
  width: 2em;
}

@media (min-width: 37.5em) {
  header#header nav#header-mobile-navigation > ul > li > a.menuToggle {
    padding: 0 1.5em;
  }
  header#header nav#header-mobile-navigation > ul > li > a.menuToggle span {
    display: none;
  }
}

@media (min-width: 37.5em) {
  header#header nav#header-mobile-navigation > ul > li > a {
    padding: 0 0 0 1.5em;
  }
}

header#header nav#header-mobile-navigation > ul > li:first-child {
  margin-left: 0;
}

footer#footer {
  background-color: #f3f2f0;
  padding-top: 7.5em;
  padding-bottom: 2em;
}

@media (min-width: 80em) {
  footer#footer {
    padding-bottom: 4em;
  }
}

footer#footer .container-item {
  grid-template-rows: auto auto;
}

footer#footer .container-item .footer-item {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 20px;
}

footer#footer .container-item div#footer-primary {
  grid-column: 4 / span 6;
  padding-bottom: 7.5em;
  border-bottom: 1px solid #504b4c;
  grid-row-gap: 1em;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 37.5em) {
  footer#footer .container-item div#footer-primary {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

footer#footer .container-item div#footer-primary .footer-primary-item {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: center;
}

@media (min-width: 37.5em) {
  footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(2), footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(3) {
    grid-column: span 3;
  }
}

@media (min-width: 64em) {
  footer#footer .container-item div#footer-primary .footer-primary-item {
    grid-column: span 2;
  }
  footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(2), footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(3) {
    grid-column: span 2;
  }
}

@media (min-width: 115em) {
  footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(2), footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(3) {
    grid-column: span 1;
  }
  footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(2) {
    grid-column-end: -2;
  }
  footer#footer .container-item div#footer-primary .footer-primary-item:nth-child(3) {
    grid-column-end: -1;
  }
}

footer#footer .container-item div#footer-primary .footer-primary-item a {
  display: inline;
}

footer#footer .container-item div#footer-primary .footer-primary-item img {
  width: 100%;
}

footer#footer .container-item div#footer-secondary {
  grid-column: 2 / -2;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2em;
}

@media (min-width: 80em) {
  footer#footer .container-item div#footer-secondary {
    grid-column: 4 / span 6;
    margin-top: 4em;
  }
}

.component {
  padding-bottom: 2em;
}

@media (min-width: 64em) {
  .component {
    padding-bottom: 7.5em;
  }
}

.component--text {
  grid-row-gap: 2em;
}

.component--text h2 {
  grid-column: 1 / -1;
  font-family: "Playfair Display SC", serif;
  font-size: 2.25em;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--text h2 {
    font-size: calc(2.25em + (3.75em - 2.25em) / 3);
  }
}

@media (min-width: 64em) {
  .component--text h2 {
    grid-column: 2 / span 4;
    text-align: right;
  }
}

@media (min-width: 80em) {
  .component--text h2 {
    font-size: 3.75em;
  }
}

.component--text h3 {
  grid-column: 1 / -1;
  font-family: "Playfair Display SC", serif;
  font-size: 1.5em;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--text h3 {
    font-size: calc(1.5em + (3em - 1.5em) / 3);
  }
}

@media (min-width: 64em) {
  .component--text h3 {
    grid-column: 2 / span 4;
    text-align: right;
  }
}

@media (min-width: 80em) {
  .component--text h3 {
    font-size: 3em;
  }
}

.component--text p {
  grid-column: 1 / -1;
  font-size: 1.125em;
  line-height: 1.2em;
  text-align: center;
}

@media (min-width: 64em) {
  .component--text p {
    grid-column: span 5 / -2;
    text-align: left;
  }
}

.component--banner img {
  grid-column: 1 / -1;
  width: 100%;
  height: auto;
}

.component--realisation {
  grid-row-gap: 2em;
  grid-auto-flow: dense;
  grid-template-rows: auto;
}

.component--realisation h2 {
  grid-column: 1 / -1;
  font-family: "Playfair Display SC", serif;
  font-size: 2.25em;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--realisation h2 {
    font-size: calc(2.25em + (3.75em - 2.25em) / 3);
  }
}

@media (min-width: 64em) {
  .component--realisation h2 {
    grid-column: 2 / span 4;
    text-align: right;
  }
}

@media (min-width: 80em) {
  .component--realisation h2 {
    font-size: 3.75em;
  }
}

.component--realisation h3 {
  grid-column: 1 / -1;
  font-style: italic;
  font-size: 2.25em;
  font-weight: 300;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--realisation h3 {
    font-size: calc(2.25em + (3.75em - 2.25em) / 3);
  }
}

@media (min-width: 64em) {
  .component--realisation h3 {
    grid-column: span 5 / -2;
    text-align: left;
  }
}

@media (min-width: 80em) {
  .component--realisation h3 {
    font-size: 3.75em;
  }
}

.component--realisation p {
  grid-column: 1 / -1;
  font-size: 1.125em;
  line-height: 1.2em;
  text-align: center;
}

@media (min-width: 64em) {
  .component--realisation p {
    grid-column: span 5 / -2;
    text-align: left;
  }
}

.component--realisation-map {
  grid-column: 1 / -1;
  width: 100%;
}

@media (min-width: 37.5em) {
  .component--realisation-map {
    height: 20em;
  }
}

@media (min-width: 48em) {
  .component--realisation-map {
    height: 25em;
  }
}

@media (min-width: 50em) {
  .component--realisation-map {
    height: 30em;
  }
}

@media (min-width: 64em) {
  .component--realisation-map {
    grid-column: span 5 / -2;
  }
}

@media (min-width: 80em) {
  .component--realisation-map {
    height: 40em;
  }
}

.component--realisation-info {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto calc(4px + 2em) auto auto auto minmax(0, 1fr);
}

@media (min-width: 64em) {
  .component--realisation-info {
    grid-column: 2 / span 4;
  }
}

.component--realisation-info-line {
  grid-column: 1 / -1;
}

@media (min-width: 64em) {
  .component--realisation-info h2 {
    grid-column: 1 / -1;
    text-align: right;
    margin-bottom: 1em;
  }
}

.component--realisation-info h3 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-bottom: 1em;
  line-height: initial;
}

@media (min-width: 37.5em) {
  .component--realisation-info h3 {
    font-size: 2em;
  }
}

@media (min-width: 64em) {
  .component--realisation-info h3 {
    grid-column: 1 / -1;
    text-align: right;
  }
}

.component--realisation-info p {
  margin-bottom: 1em;
}

@media (min-width: 64em) {
  .component--realisation-info p {
    grid-column: 1 / -1;
    text-align: right;
  }
}

.component--realisation-info ul {
  grid-column: 1 / -1;
}

.component--realisation-info ul > li {
  text-align: center;
}

@media (min-width: 64em) {
  .component--realisation-info ul > li {
    text-align: right;
  }
}

#menu {
  -moz-transform: translateX(24em);
  -webkit-transform: translateX(24em);
  -ms-transform: translateX(24em);
  transform: translateX(24em);
  -moz-transition: -moz-transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  -ms-transition: -ms-transform 0.5s ease;
  transition: transform 0.5s ease;
  -webkit-overflow-scrolling: touch;
  background: #f3f2f0;
  color: #504b4c;
  height: 100%;
  max-width: 80%;
  overflow-y: auto;
  padding: 3em 2em;
  position: fixed;
  right: 0;
  top: 0;
  width: 20em;
  z-index: 902;
}

#menu ul {
  list-style: none;
  padding: 0;
}

#menu ul > li {
  margin: 0.5em 0 0 0;
}

#menu ul > li:first-child {
  border-top: 0 !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

#menu ul > li > a {
  border: 0;
  color: #504b4c;
  display: block;
  font-size: 1em;
  letter-spacing: initial;
  outline: 0;
  text-decoration: none;
}

#menu .close {
  border: 0;
  cursor: pointer;
  display: block;
  height: 1.4em;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: middle;
  width: 1.4em;
  text-align: center;
  color: #504b4c;
  font-size: 1.4em;
  margin: 0.75em 0.75em 0 0;
}

#menu div.line {
  width: 100px;
  height: 10px;
  background-color: #504b4c;
  margin: 1em 0;
}

@media (min-width: 20em) {
  #menu {
    padding: 3em 1.5em;
  }
}

@media (min-width: 37.5em) {
  #menu {
    display: none;
  }
}

body.is-menu-visible #page-wrapper {
  opacity: 0.35;
}

body.is-menu-visible #page-wrapper:before {
  display: block;
}

body.is-menu-visible #menu {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.component--images {
  grid-row-gap: 2em;
}

.component--images-item {
  grid-column: 1 / -1;
  width: 100%;
  position: relative;
  cursor: pointer;
}

@media (min-width: 64em) {
  .component--images-item:nth-child(1) {
    grid-column: 1 / span 5;
  }
  .component--images-item:nth-child(2) {
    grid-column: span 5 / -3;
  }
}

.component--images-item img {
  width: 100%;
  height: auto;
}

.component--images-wrapper {
  grid-column: 1 / -1;
  width: 100%;
  position: relative;
  cursor: pointer;
}

@media (min-width: 64em) {
  .component--images-wrapper:nth-child(1) {
    grid-column: 1 / span 5;
  }
  .component--images-wrapper:nth-child(2) {
    grid-column: span 5 / -3;
  }
}

.component--images-wrapper:hover .component--images-wrapper-overlay {
  display: block;
}

.component--images-wrapper-image {
  width: 100%;
  padding-top: 67%;
  position: relative;
}

.component--images-wrapper-image img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.component--images-wrapper-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.component--images-wrapper-overlay h3 {
  margin-top: 1em;
  font-style: italic;
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
  color: white;
}

@media (min-width: 37.5em) {
  .component--images-wrapper-overlay h3 {
    margin-top: 1.5em;
    font-style: italic;
    font-size: 1.5em;
  }
}

.component--images-wrapper-overlay p {
  margin-top: 1em;
  font-size: 0.8em;
  text-align: center;
  padding: 0 1em;
  color: white;
}

@media (min-width: 37.5em) {
  .component--images-wrapper-overlay p {
    margin-top: 1.5em;
    font-size: 1em;
    padding: 0 1.5em;
    line-height: 1.2em;
  }
}

.component--images-wrapper-overlay p em {
  font-style: italic;
}

.component--images-wrapper-overlay p a {
  color: white;
  text-decoration: underline;
}

.component--images-wrapper-button {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.component--images-wrapper-button img {
  width: 50%;
  height: auto;
}

.component--images-wrapper-button img.component--images-wrapper-button-hide {
  display: none;
}

.component--images-reverse {
  grid-row-gap: 2em;
}

.component--images-reverse .component--images-wrapper {
  grid-column: 1 / -1;
  width: 100%;
}

@media (min-width: 64em) {
  .component--images-reverse .component--images-wrapper:nth-child(1) {
    grid-column: 3 / span 5;
  }
  .component--images-reverse .component--images-wrapper:nth-child(2) {
    grid-column: span 5 / -1;
  }
}

.component--cards {
  grid-row-gap: 2em;
  grid-template-rows: auto auto;
}

@media (min-width: 64em) {
  .component--cards {
    grid-template-rows: auto;
  }
}

.component--cards label.component--card {
  grid-column: 1 / -1;
  width: 100%;
  height: 51vw;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  display: block;
  position: relative;
  cursor: pointer;
}

@media (min-width: 460px) {
  .component--cards label.component--card {
    height: 58vw;
  }
}

@media (min-width: 35.5em) {
  .component--cards label.component--card {
    height: 60vw;
  }
}

@media (min-width: 64em) {
  .component--cards label.component--card {
    height: 25.5vw;
  }
  .component--cards label.component--card:nth-child(1) {
    grid-column: 1 / span 5;
  }
  .component--cards label.component--card:nth-child(2) {
    grid-column: span 5 / -3;
  }
}

@media (min-width: 160em) {
  .component--cards label.component--card {
    height: 19.8vw;
  }
}

.component--cards label.component--card input[type=checkbox] {
  display: none;
}

.component--cards label.component--card:hover .component--card-panel {
  -webkit-transform: rotateX(5deg);
  transform: rotateX(5deg);
  box-shadow: 0 20px 20px rgba(50, 50, 50, 0.2);
}

.component--cards label.component--card:hover :checked + div.component--card-panel {
  transform: rotateX(175deg);
  -webkit-transform: rotateX(175deg);
  box-shadow: 0 20px 20px rgba(255, 255, 255, 0.2);
}

.component--cards label.component--card :checked + .component--card-panel {
  transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
}

.component--cards label.component--card .component--card-panel {
  position: relative;
  width: 100%;
  height: 51vw;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 20;
}

@media (min-width: 460px) {
  .component--cards label.component--card .component--card-panel {
    height: 58vw;
  }
}

@media (min-width: 35.5em) {
  .component--cards label.component--card .component--card-panel {
    height: 60vw;
  }
}

@media (min-width: 64em) {
  .component--cards label.component--card .component--card-panel {
    height: 25.5vw;
  }
}

@media (min-width: 160em) {
  .component--cards label.component--card .component--card-panel {
    height: 19.8vw;
  }
}

.component--cards label.component--card .component--card-panel div {
  position: absolute;
  width: 100%;
  height: 51vw;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

@media (min-width: 460px) {
  .component--cards label.component--card .component--card-panel div {
    height: 57vw;
  }
}

@media (min-width: 35.5em) {
  .component--cards label.component--card .component--card-panel div {
    height: 60vw;
  }
}

@media (min-width: 64em) {
  .component--cards label.component--card .component--card-panel div {
    height: 25.5vw;
  }
}

@media (min-width: 160em) {
  .component--cards label.component--card .component--card-panel div {
    height: 19.8vw;
  }
}

.component--cards label.component--card .component--card-panel div.component--card-panel-back {
  background: #9ba587;
  color: white;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.component--cards label.component--card .component--card-panel div.component--card-panel-back h3 {
  margin-top: 1em;
  font-style: italic;
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--cards label.component--card .component--card-panel div.component--card-panel-back h3 {
    margin-top: 1.5em;
    font-style: italic;
    font-size: 1.5em;
  }
}

.component--cards label.component--card .component--card-panel div.component--card-panel-back p {
  margin-top: 1em;
  font-size: 0.8em;
  text-align: center;
  padding: 0 1em;
}

@media (min-width: 37.5em) {
  .component--cards label.component--card .component--card-panel div.component--card-panel-back p {
    margin-top: 1.5em;
    font-size: 1em;
    padding: 0 1.5em;
    line-height: 1.2em;
  }
}

.component--cards label.component--card .component--card-panel div.component--card-panel-back p em {
  font-style: italic;
}

.component--cards label.component--card .component--card-panel div.component--card-panel-back p a:link {
  color: white;
  text-decoration: underline;
}

.component--cards label.component--card .component--card-panel div img {
  width: 100%;
  height: auto;
}

@media (min-width: 64em) {
  .component--cards-reverse label.component--card:nth-child(1) {
    grid-column: 3 / span 5;
  }
  .component--cards-reverse label.component--card:nth-child(2) {
    grid-column: span 5 / -1;
  }
}

.component--publication {
  grid-row-gap: 1.5em;
}

.component--publication li {
  grid-column: 1 / -1;
  position: relative;
}

@media (min-width: 64em) {
  .component--publication li {
    grid-column: span 5;
  }
  .component--publication li:nth-child(3n) {
    grid-column: 3 / span 5;
  }
}

.component--publication li img {
  width: 100%;
  height: auto;
  margin-bottom: 1em;
}

.component--publication li h2 {
  font-family: "Playfair Display SC", serif;
  font-size: 1.75em;
  text-align: center;
  margin-bottom: 1em;
}

@media (min-width: 80em) {
  .component--publication li h2 {
    font-size: 2.25em;
    margin-bottom: 2em;
  }
}

.component--publication li p {
  font-size: 1.125em;
  line-height: 1.2em;
  margin: 0 1em 1em;
}

.component--publication li .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.component--publication li .overlay img {
  width: 96px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.component--publication li:hover .overlay {
  display: block;
}

.component--contact {
  grid-row-gap: 2em;
  grid-auto-flow: dense;
  grid-template-rows: auto;
}

.component--contact h2 {
  grid-column: 1 / -1;
  font-family: "Playfair Display SC", serif;
  font-size: 2.25em;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--contact h2 {
    font-size: calc(2.25em + (3.75em - 2.25em) / 3);
  }
}

@media (min-width: 64em) {
  .component--contact h2 {
    grid-column: 2 / span 4;
    text-align: right;
  }
}

@media (min-width: 80em) {
  .component--contact h2 {
    font-size: 3.75em;
  }
}

.component--contact h3 {
  grid-column: 1 / -1;
  font-style: italic;
  font-size: 2.25em;
  font-weight: 300;
  text-align: center;
}

@media (min-width: 37.5em) {
  .component--contact h3 {
    font-size: calc(2.25em + (3.75em - 2.25em) / 3);
  }
}

@media (min-width: 64em) {
  .component--contact h3 {
    grid-column: span 5 / -2;
    text-align: left;
  }
}

@media (min-width: 80em) {
  .component--contact h3 {
    font-size: 3.75em;
  }
}

.component--contact p {
  grid-column: 1 / -1;
  font-size: 1.125em;
  line-height: 1.2em;
  text-align: center;
}

@media (min-width: 64em) {
  .component--contact p {
    grid-column: span 5 / -2;
    text-align: left;
  }
}

.component--contact-map {
  grid-column: 1 / -1;
  width: 100%;
}

@media (min-width: 37.5em) {
  .component--contact-map {
    height: 20em;
  }
}

@media (min-width: 48em) {
  .component--contact-map {
    height: 25em;
  }
}

@media (min-width: 50em) {
  .component--contact-map {
    height: 30em;
  }
}

@media (min-width: 64em) {
  .component--contact-map {
    grid-column: span 5 / -2;
  }
}

@media (min-width: 80em) {
  .component--contact-map {
    height: 40em;
  }
}

.component--contact-form {
  grid-column: 1 / -1;
  width: 100%;
  height: auto;
}

@media (min-width: 64em) {
  .component--contact-form {
    grid-column: span 5 / -2;
  }
}

.component--contact-form label {
  font-weight: 300;
  font-style: italic;
  font-size: 1.25em;
  text-align: center;
  display: block;
}

@media (min-width: 35.5em) {
  .component--contact-form label {
    text-align: left;
  }
}

.component--contact-form input, .component--contact-form textarea {
  background-color: #f3f2f0;
  border: 1px solid #f3f2f0;
  box-shadow: none;
  display: block;
  width: 100%;
  padding: 0.5em;
  margin: 0.5em 0 1.5em;
  border: none;
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  font-size: 1em;
}

.component--contact-form button {
  display: block;
  width: 80%;
  max-width: 300px;
  background-color: #504b4c;
  border-color: #504b4c;
  color: #f3f2f0;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Lato", sans-serif;
  padding: 1em;
  display: block;
  text-align: center;
  font-size: 1.5em;
  margin: 1.5em auto 0;
  border-radius: 4px;
}

.component--contact-form button:hover {
  background-color: black;
  border-color: black;
}

.component--contact-info {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto calc(4px + 2em) auto auto auto minmax(0, 1fr);
}

@media (min-width: 64em) {
  .component--contact-info {
    grid-column: 2 / span 4;
  }
}

.component--contact-info-line {
  grid-column: 1 / -1;
}

.component--contact-info h2 {
  font-size: 1.75em;
  margin-bottom: 1em;
}

@media (min-width: 64em) {
  .component--contact-info h2 {
    grid-column: 1 / -1;
    text-align: right;
  }
}

.component--contact-info h3 {
  font-size: 1.75em;
  margin-bottom: 1em;
}

@media (min-width: 64em) {
  .component--contact-info h3 {
    grid-column: 1 / -1;
    text-align: right;
  }
}

.component--contact-info p {
  margin-bottom: 1em;
}

@media (min-width: 64em) {
  .component--contact-info p {
    grid-column: 1 / -1;
    text-align: right;
  }
}

.component--banner-group {
  grid-row-gap: 20px;
}

.component--banner-group-gallery {
  grid-column: 1 / -1;
  grid-row: span 1;
  position: relative;
  width: 100%;
}

@media (min-width: 50em) {
  .component--banner-group-gallery {
    grid-column: 1 / span 8;
    grid-row: 1 / span 2;
  }
}

.component--banner-group-gallery-gallery-image {
  width: 100%;
  padding-top: 67%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.component--banner-group-gallery-gallery-image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.component--banner-group-gallery .slick-prev, .component--banner-group-gallery .slick-next {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20%;
  max-width: 60px;
  height: auto;
  padding: 0;
  z-index: 100;
  background: transparent;
  border: none;
  transform: translate(0, -50%);
}

.component--banner-group-gallery .slick-prev img, .component--banner-group-gallery .slick-next img {
  width: 20%;
  min-width: 60px;
  height: auto;
}

.component--banner-group-gallery .slick-next {
  left: 100%;
  transform: translate(-100%, -50%);
}

.component--banner-group-image {
  grid-column: 1 / -1;
  width: 100%;
  padding-top: 65%;
  position: relative;
}

@media (min-width: 50em) {
  .component--banner-group-image {
    grid-column: span 4 / -1;
  }
}

.component--banner-group-image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.component--well {
  background: #f3f2f0;
  padding: 2rem;
  margin-bottom: 3rem !important;
}

@media (min-width: 64em) {
  .component--well {
    padding: 3rem 25%;
  }
}

@media (min-width: 64em) {
  .component--well {
    padding: 3rem 30%;
  }
}

.component--well p {
  grid-column: 1 / -1;
  margin-bottom: 1.3rem;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 600;
}

@media (min-width: 64em) {
  .component--well p {
    font-size: 1.2rem;
    margin-bottom: 1.4rem;
    line-height: 1.5rem;
  }
}
