.component--images {
    grid-row-gap: 2em;

    &-item {
        grid-column: 1 / -1;
        width: 100%;
        position: relative;
        cursor: pointer;

        @include breakpoint($bp-ml) {
            &:nth-child(1) {
                grid-column: 1 / span 5;
            }
            &:nth-child(2) {
                grid-column: span 5 / -3;
            }
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-wrapper {
        grid-column: 1 / -1;
        width: 100%;
        position: relative;
        cursor: pointer;

        @include breakpoint($bp-ml) {
            &:nth-child(1) {
                grid-column: 1 / span 5;
            }
            &:nth-child(2) {
                grid-column: span 5 / -3;
            }
        }

        &:hover {
            .component--images-wrapper-overlay {
                display: block;
            }
        }

        &-image {
            width: 100%;
            padding-top: 67%;
            position: relative;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
            }
        }

        &-overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize($c-black, 0.25);

            h3 {
                margin-top: 1em;
                font-style: italic;
                font-size: 1.2em;
                font-weight: 300;
                text-align: center;
                color: $c-white;

                @include breakpoint($bp-s) {
                    margin-top: 1.5em;
                    font-style: italic;
                    font-size: 1.5em;
                }
            }

            p {
                margin-top: 1em;
                font-size: 0.8em;
                text-align: center;
                padding: 0 1em;
                color: $c-white;

                @include breakpoint($bp-s) {
                    margin-top: 1.5em;
                    font-size: 1em;
                    padding: 0 1.5em;
                    line-height: 1.2em;
                }

                em {
                    font-style: italic;
                }

                a {
                    color: $c-white;
                    text-decoration: underline;
                }
            }
        }

        &-button {
            position: absolute;
            bottom: 0;
            left: 0;
            pointer-events:none;

            img {
                width: 50%;
                height: auto;

                &.component--images-wrapper-button-hide {
                    display: none;
                }
            }
        }

    }
}

.component--images-reverse {
    grid-row-gap: 2em;

    .component--images-wrapper {
        grid-column: 1 / -1;
        width: 100%;

        @include breakpoint($bp-ml) {
            &:nth-child(1) {
                grid-column: 3 / span 5;
            }
            &:nth-child(2) {
                grid-column: span 5 / -1;
            }
        }
    }
}
