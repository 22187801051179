.component {
    padding-bottom: 2em;

    @include breakpoint($bp-ml) {
        padding-bottom: 7.5em;
    }

    &--text {
        grid-row-gap: 2em;

        h2 {
            grid-column: 1 / -1;
            font-family: $f-display;
            font-size: 2.25em;
            text-align: center;

            @include breakpoint($bp-s) {
                font-size: calc(2.25em + (3.75em - 2.25em) / 3);
            }

            @include breakpoint($bp-ml) {
                grid-column: 2 / span 4;
                text-align: right;
            }

            @include breakpoint($bp-l) {
                font-size: 3.75em;
            }
        }

        h3 {
            grid-column: 1 / -1;
            font-family: $f-display;
            font-size: 1.5em;
            text-align: center;

            @include breakpoint($bp-s) {
                font-size: calc(1.5em + (3em - 1.5em) / 3);
            }

            @include breakpoint($bp-ml) {
                grid-column: 2 / span 4;
                text-align: right;
            }

            @include breakpoint($bp-l) {
                font-size: 3em;
            }
        }

        p {
            grid-column: 1 / -1;
            font-size: 1.125em;
            line-height: 1.2em;
            text-align: center;

            @include breakpoint($bp-ml) {
                grid-column: span 5 / -2;
                text-align: left;
            }
        }
    }

    &--banner {

        img {
            grid-column: 1 / -1;
            width: 100%;
            height: auto;
        }
    }

    &--realisation {
        grid-row-gap: 2em;
        grid-auto-flow: dense;
        grid-template-rows: auto;

        h2 {
            grid-column: 1 / -1;
            font-family: $f-display;
            font-size: 2.25em;
            text-align: center;

            @include breakpoint($bp-s) {
                font-size: calc(2.25em + (3.75em - 2.25em) / 3);
            }

            @include breakpoint($bp-ml) {
                grid-column: 2 / span 4;
                text-align: right;
            }

            @include breakpoint($bp-l) {
                font-size: 3.75em;
            }
        }

        h3 {
            grid-column: 1 / -1;
            font-style: italic;
            font-size: 2.25em;
            font-weight: 300;
            text-align: center;

            @include breakpoint($bp-s) {
                font-size: calc(2.25em + (3.75em - 2.25em) / 3);
            }

            @include breakpoint($bp-ml) {
                grid-column: span 5 / -2;
                text-align: left;
            }

            @include breakpoint($bp-l) {
                font-size: 3.75em;
            }
        }

        p {
            grid-column: 1 / -1;
            font-size: 1.125em;
            line-height: 1.2em;
            text-align: center;

            @include breakpoint($bp-ml) {
                grid-column: span 5 / -2;
                text-align: left;
            }
        }

        &-map {
            grid-column: 1 / -1;
            width: 100%;

            @include breakpoint($bp-s) {
                height: 20em;
            }

            @include breakpoint($bp-sm) {
                height: 25em;
            }

            @include breakpoint($bp-m) {
                height: 30em;
            }

            @include breakpoint($bp-ml) {
                grid-column: span 5 / -2;
            }

            @include breakpoint($bp-l) {
                height: 40em;
            }
        }

        &-info {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto calc(4px + 2em) auto auto auto minmax(0, 1fr);

            @include breakpoint($bp-ml) {
                grid-column: 2 / span 4;
            }

            &-line {
                grid-column: 1 / -1;
            }

            h2 {
                @include breakpoint($bp-ml) {
                    grid-column: 1 / -1;
                    text-align: right;
                    margin-bottom: 1em;
                }
            }

            h3 {
                font-family: $f-main;
                font-weight: 300;
                margin-bottom: 1em;
                line-height: initial;

                @include breakpoint($bp-s) {
                    font-size: 2em;
                }

                @include breakpoint($bp-ml) {
                    grid-column: 1 / -1;
                    text-align: right;
                }
            }

            p {
                margin-bottom: 1em;

                @include breakpoint($bp-ml) {
                    grid-column: 1 / -1;
                    text-align: right;
                }
            }

            ul {
                grid-column: 1 / -1;

                > li {
                    text-align: center;

                    @include breakpoint($bp-ml) {
                        text-align: right;
                    }
                }
            }
        }
    }
}
