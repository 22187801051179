.component--cards {
    grid-row-gap: 2em;
    grid-template-rows: auto auto;

    @include breakpoint($bp-ml) {
        grid-template-rows: auto;
    }

    label.component--card {
        grid-column: 1 / -1;
        width: 100%;
        height: 51vw;
        -webkit-perspective: 1000px;
        perspective: 1000px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        display: block;
        position: relative;
        cursor: pointer;

        @include breakpoint(460px) {
            height: 58vw;
        }

        @include breakpoint($bp-xs) {
            height: 60vw;
        }

        @include breakpoint($bp-ml) {
            height: 25.5vw;

            &:nth-child(1) {
                grid-column: 1 / span 5;
            }
            &:nth-child(2) {
                grid-column: span 5 / -3;
            }
        }

        @include breakpoint($bp-xxl) {
            height: 19.8vw;
        }

        input[type=checkbox] {
            display: none;
        }

        &:hover .component--card-panel {
            -webkit-transform: rotateX(5deg);
            transform: rotateX(5deg);
            box-shadow: 0 20px 20px rgba(50,50,50,.2);
        }

        &:hover :checked + div.component--card-panel {
            transform: rotateX(175deg);
            -webkit-transform: rotateX(175deg);
            box-shadow: 0 20px 20px rgba(255,255,255,.2);
        }

        :checked + .component--card-panel {
            transform: rotateX(180deg);
            -webkit-transform: rotateX(180deg);
        }

        .component--card-panel {
            position: relative;
            width: 100%;
            height: 51vw;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -webkit-transition: all 400ms;
            transition: all 400ms;
            z-index: 20;

            @include breakpoint(460px) {
                height: 58vw;
            }

            @include breakpoint($bp-xs) {
                height: 60vw;
            }

            @include breakpoint($bp-ml) {
                height: 25.5vw;
            }

            @include breakpoint($bp-xxl) {
                height: 19.8vw;
            }

            div {
                position: absolute;
                width: 100%;
                height: 51vw;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                border-radius: 2px;

                @include breakpoint(460px) {
                    height: 57vw;
                }

                @include breakpoint($bp-xs) {
                    height: 60vw;
                }

                @include breakpoint($bp-ml) {
                    height: 25.5vw;
                }

                @include breakpoint($bp-xxl) {
                    height: 19.8vw;
                }

                &.component--card-panel-back {
                    background: $c-zozan-kaki;
                    color: $c-white;
                    -webkit-transform: rotateX(180deg);
                    transform: rotateX(180deg);

                    h3 {
                        margin-top: 1em;
                        font-style: italic;
                        font-size: 1.2em;
                        font-weight: 300;
                        text-align: center;

                        @include breakpoint($bp-s) {
                            margin-top: 1.5em;
                            font-style: italic;
                            font-size: 1.5em;
                        }
                    }

                    p {
                        margin-top: 1em;
                        font-size: 0.8em;
                        text-align: center;
                        padding: 0 1em;

                        @include breakpoint($bp-s) {
                            margin-top: 1.5em;
                            font-size: 1em;
                            padding: 0 1.5em;
                            line-height: 1.2em;
                        }

                        em {
                            font-style: italic;
                        }

                        a:link {
                            color: $c-white;
                            text-decoration: underline;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.component--cards-reverse {

    label.component--card {

        @include breakpoint($bp-ml) {

            &:nth-child(1) {
                grid-column: 3 / span 5;
            }
            &:nth-child(2) {
                grid-column: span 5 / -1;
            }
        }
    }
}
