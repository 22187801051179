footer#footer {
  background-color: $c-zozan-beige;
  padding-top: 7.5em;
  padding-bottom: 2em;

  @include breakpoint($bp-l) {
    padding-bottom: 4em;
  }

  .container-item {
    grid-template-rows: auto auto;

    .footer-item {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 20px;
    }

    div#footer-primary {
      grid-column: 4 / span 6;
      padding-bottom: 7.5em;
      border-bottom: 1px solid $c-zozan-grey;
      grid-row-gap: 1em;
      display: grid;
      grid-template-columns: 1fr;

      @include breakpoint($bp-s) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }

      .footer-primary-item {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        align-content: center;

        @include breakpoint($bp-s) {
          &:nth-child(2), &:nth-child(3) {
            grid-column: span 3;
          }
        }

        @include breakpoint($bp-ml) {
          grid-column: span 2;

          &:nth-child(2), &:nth-child(3) {
            grid-column: span 2;
          }
        }

        @include breakpoint($bp-xl) {
          &:nth-child(2), &:nth-child(3) {
            grid-column: span 1;
          }
          &:nth-child(2) {
            grid-column-end: -2;
          }
          &:nth-child(3) {
            grid-column-end: -1;
          }
        }

        a {
          display: inline;
        }

        img {
          width: 100%;
        }
      }
    }

    div#footer-secondary {
      grid-column: 2 / -2;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 2em;

      @include breakpoint($bp-l) {
        grid-column: 4 / span 6;
        margin-top: 4em;
      }
    }
  }
}