.component--well {
    background: $c-zozan-beige;
    padding: 2rem;
    margin-bottom: 3rem !important;

    @include breakpoint($bp-ml) {
        padding: 3rem 25%;
    }

    @include breakpoint($bp-ml) {
        padding: 3rem 30%;
    }

    p {
        grid-column: 1 / -1;
        margin-bottom: 1.3rem;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 600;

        @include breakpoint($bp-ml) {
            font-size: 1.2rem;
            margin-bottom: 1.4rem;
            line-height: 1.5rem;
        }
    }
}
