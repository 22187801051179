.component--publication {
    grid-row-gap: 1.5em;

    li {
        grid-column: 1 / -1;
        position: relative;

        @include breakpoint($bp-ml) {
            grid-column: span 5;

            &:nth-child(3n) {
                grid-column: 3 / span 5;
            }
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 1em;
        }

        h2 {
            font-family: $f-display;
            font-size: 1.75em;
            text-align: center;
            margin-bottom: 1em;

            @include breakpoint($bp-l) {
                font-size: 2.25em;
                margin-bottom: 2em;
            }
        }

        p {
            font-size: 1.125em;
            line-height: 1.2em;
            margin: 0 1em 1em;
        }

        .overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize($c-black, 0.25);

            img {
                width: 96px;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            .overlay {
                display: block;
            }
        }
    }
}
